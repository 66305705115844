.contact-us-container
{
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    padding-top: 80px;
}
  
.form-section
{
    background-color: #f9f9f9;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
  
.form-field
{
    margin-bottom: 1.5rem;
}
  
label
{
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}
  
input, textarea
{
    width: 100%;
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    resize: none;
}
  
.submit-btn
{
    margin-top: 1.5rem;
    width: 100%;
    padding: 0.75rem;
}
  
.company-details
{
    margin-top: 2rem;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@media(max-width: 768px)
{
    .form-section, .company-details
    {
        padding: 1rem;
    }
    .submit-btn
    {
        width: 100%;
    }
}
  
@media(max-width: 480px)
{
    .form-section, .company-details
    {
        padding: 0.5rem;
    }
}
  