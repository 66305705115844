@import url('../css/root.css');

.home-container
{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../images/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: white;
    z-index: 1;
}

.home-container::before
{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.home-header
{
    text-align: center;
    margin-bottom: 30px;
    z-index: 1;
}

.home-header h1 
{
    font-size: 2.5rem;
    color: white;
}

.home-header p
{
    font-size: 1.2rem;
    color: white;
}

.home-content
{
    width: 80%;
    max-width: 800px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 20px;
    border-radius: 10px;
    z-index: 1;
}

.home-buttons
{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}

.primary-btn, .secondary-btn
{
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    outline: none;
    transition: background-color 0.3s ease;
}

.primary-btn
{
    background-color: var(--gdc-blue);
    color: white;
}

.secondary-btn
{
    background-color: var(--gdc-background);
    color: var(--gdc-blue);
    border: 1px solid var(--gdc-blue);
}

.primary-btn:hover
{
    background-color: var(--gdc-blue);
}

.secondary-btn:hover
{
    background-color: var(--gdc-background);
}

.home-intro
{
    font-size: 1.1rem;
    color: var(--gdc-background);
    line-height: 1.6;
    z-index: 1;
}
