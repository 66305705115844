/* import this in every TSX file 
  
@import url('../css/root.css');

*/

:root {
    /* GDC Client Specific Colours */
    --gdc-blue: #81d4fa;
    --gdc-orange: #2196f3;
    --gdc-background: #f5f5f5;
}    
