.page-container
{
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
}
  

  
.dimming-overlay
{
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
  
.modal-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    z-index: 1;
}
  
.modal-content
{
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
  
.close-icon
{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 2;
}

@media (max-width: 768px)
{
    .p-field
    {
        width: 100%;
    }
}

.custom-field-padding
{
    padding: 0 1rem;
}

.modal-content
{
    width: 95%; 
    padding: 10px;
}