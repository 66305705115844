.page-container
{
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.background-overlay
{
    background-image: url('../images/background.png');
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}

.dimming-overlay
{
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.signout-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 1;
}

.signout-content
{
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    width: 400px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

h1
{
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.return-home-btn
{
    background-color: #003399;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
}

.return-home-btn:hover
{
    background-color: #002266;
}
