/* Common to most/all styles across the site */
@import url('../css/root.css');

/*  var(<global variable>)   */ 
/* ========================= */
/*  var(--gdc-blue);                  <-- Primary Customer Colour
    var(--gdc-orange);               <-- Secondary Customer Colour
    var(--gdc-background);  <-- Form Backuground Colour ( Off white )
*/

/* ALPHABETICAL ORDER */
/* =========================================================================== */

.background-dimming-overlay { /* Dims the background by 0.1 10% Useful when child/modal popup forms open */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1); /* Adjust opacity as needed to dim by 10% */
    z-index: 1;
}

.background { /* wrap each page to force the background as the topmost <DIV> container */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: url('../images/background.png') center center / cover no-repeat;
    overflow: hidden;
}


.footer
{
    width: 100%;
    z-index: 2;
}

.image-text {
    vertical-align: middle;
    margin-left: .5rem;
}




/* Ensure the background image remains fixed as the form scrolls */
body, html {
    height: 100%;
    margin: 0;
    overflow: hidden; /* Prevent scrolling */
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-weight: normal;
    color: #495057;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: .5em;
    margin-bottom: 50px;
}

h1, h2, h3, h4, h5, h6 {
    margin: 1.5rem 0 1rem 0;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
    color: inherit;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

html {
    font-size: 14px;
}

p {
    line-height: 1.5;
    margin: 0 0 1rem 0;
}

img.flag {
    width:30px
}

